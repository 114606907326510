<template>
  <ViewContract
    :display="showViewContract"
    :contract="contractViewData"
    @closeViewContract="closeContract"
  >
  </ViewContract>
  <CreateEditContract
    :display="displayContractDialog"
    :dataValue="contract"
    @closeDialog="closeDialog"
    :createEditAction="createEditAction"
    @getApplications="getApplications"
  ></CreateEditContract>
  <ViewApplication
    :display="openViewApplication"
    @closeViewApplication="closeViewApplication"
    :loading="loadingView"
  >
  </ViewApplication>
  <Dialog
    :header="$t('global.warning')"
    v-model:visible="showRejectModal"
    :draggable="false"
    :modal="true"
    :style="{ width: '40vw' }"
  >
    <span class="text-center">
      {{ $t("applications.pleaseAddComment") }}
    </span>
    <div class="mt-3 text-center">
      <Textarea
        v-model="rejectApplicationObject.comments"
        :autoResize="true"
        rows="5"
        class="w-full"
        :class="{
          'p-invalid':
            (rejectApplicationObject.comments == null ||
              rejectApplicationObject.comments.trim().length == 0) &&
            showRejectError,
        }"
      />
    </div>
    <ul
      v-if="
        (rejectApplicationObject.comments == null ||
          rejectApplicationObject.comments.trim().length == 0) &&
        showRejectError
      "
      class="p-error text-xs col-10 pl-3 mt-0"
    >
      <li>
        {{ $t("applications.addComment") }}
      </li>
    </ul>
    <template #footer>
      <Button
        :label="$t('global.close')"
        class="p-button-sm"
        @click="closeRejectModel"
      />
      <Button
        :label="$t('global.reject')"
        class="p-button-danger p-button-sm"
        @click="rejectApplication"
        iconPos="right"
        :loading="buttonLoading"
      />
    </template>
  </Dialog>

  <Card>
    <template #title v-if="loading || applications.length > 0">
      <div class="grid grid-nogutter">
        <div class="col-4">
          <h4>
            {{
              checkRole(["sales"])
                ? $t("contracts.title")
                : $t("applications.title")
            }}
          </h4>
        </div>
        <div class="col-8 md:col-2 md:col-offset-6 text-right">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
      </div>
    </template>
    <template #content v-if="loading || applications.length > 0">
      <DataTable
        :value="applications"
        :paginator="true"
        :rows="10"
        :filters="filters"
        dataKey="id"
        :loading="loading"
        showGridlines
        class="p-datatable-applications"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        :currentPageReportTemplate="$tableString"
        filterDisplay="menu"
        ref="tableApplications"
        removableSort
        breakpoint="1600px"
        scrollHeight="flex"
        :globalFilterFields="[
          'id',
          'creationDate',
          'companyRepresentative',
          'status',
          'reviewDate',
          'approveOrRejDate',
          'comments',
        ]"
        sortField="createdDate"
        :sortOrder="-1"
      >
        <template #header>
          <div>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('global.search')"
              />
            </span>
          </div>
        </template>
        <template #empty>{{ $t("applications.noApplicationsFound") }}</template>
        <Column
          field="id"
          :header="$t('applications.applicationId')"
          :showFilterMatchModes="false"
          :sortable="true"
          class="w-1"
        >
          <template #body="{ data }">
            <span class="text-right">{{ data.id }}</span>
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="number"
              v-model.number="filterModel.value"
              class="p-column-filter"
              :placeholder="$t('global.searchById')"
            />
          </template>
          <template #filterapply="{ field, filterModel }">
            <Button
              :label="$t('global.apply')"
              class="p-button-primary"
              @click="filterApply(field, filterModel)"
            />
          </template>
          <template #filterclear="{ field }">
            <Button
              :label="$t('global.clear')"
              class="p-button-danger"
              @click="filterClear(field)"
            />
          </template>
        </Column>
        <Column
          field="createdDate"
          :header="$t('applications.applicationDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ $dayjs(data.createdDate).format("DD/MM/YYYY") }}
          </template>
        </Column>
        <Column
          field="companyName"
          :header="$t('applications.company')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyName }}
          </template>
        </Column>
        <Column
          field="companyRepresentative"
          :header="$t('applications.companyRep')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ data.companyRepresentative }}
          </template>
        </Column>
        <Column
          :field="checkRole(['sales']) ? 'contractStatus' : 'status'"
          :header="$t('global.status')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              checkRole(["sales"])
                ? Object.keys(data.contract).length == 0
                  ? translatedStatus("pre-contract")
                  : translatedStatus(data.contractStatus)
                : translatedStatus(data.status)
            }}
          </template>
        </Column>
        <Column
          v-if="checkRole(['sales'])"
          field="contractExtraCost"
          :header="$t('applications.extraCost')"
          :sortable="true"
        >
          <template #body="{ data }">
            <template v-if="Object.keys(data.contract).length == 0">
              {{ $t("global.noValue") }}
            </template>
            <template v-else>
              {{ data.contractExtraCost }}
            </template>
          </template>
        </Column>
        <Column
          v-if="checkRole(['sales'])"
          field="contractPrice"
          :header="$t('applications.price')"
          :sortable="true"
        >
          <template #body="{ data }">
            <template v-if="Object.keys(data.contract).length == 0">
              {{ $t("global.noValue") }}
            </template>
            <template v-else>
              {{ data.contractPrice }}
            </template>
          </template>
        </Column>
        <Column
          field="desiredAuditDate"
          :header="$t('applications.reviewDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{ $dayjs(data.desiredAuditDate).format("DD/MM/YYYY") }}
          </template>
        </Column>
        <Column
          field="approvalRejectionDate"
          :header="$t('applications.approveRejectDate')"
          :sortable="true"
        >
          <template #body="{ data }">
            {{
              data.approvalRejectionDate
                ? $dayjs(data.approvalRejectionDate).format("DD/MM/YYYY")
                : $t("global.noDate")
            }}
          </template>
        </Column>
        <Column
          :header="$t('global.certificationType')"
          :sortable="true"
        >
        <template #body="{ data }">
          {{certCategoryById(data.certificationId)?.schemeTitle}}
        </template>
        </Column>
        <Column
          field="comments"
          :header="$t('applications.comments')"
          :sortable="true"
        >
          <template #body="{ data, index }">
            <!-- <div v-if="checkRole(['sales'])" class="text-center">
              {{ data.contract.comments }}
            </div> -->
            <CommentsFields
              :dataValue="checkRole(['sales']) ? data.contract : data"
              :indexValue="index"
              :type="checkRole(['sales']) ? 'contract' : 'application'"
            ></CommentsFields>
          </template>
        </Column>
        <Column
          field="reviewer"
          :header="$t('applications.assignTo')"
          v-if="checkRole(['head-application-reviewer'])"
        >
          <template #body="{ data, index }">
            <Dropdown
              v-model="data.applicationReviewerId"
              :options="users"
              optionValue="id"
              :loading="loadingReviewers && loadingReviewersIndex == index"
              @change="selectReviewer($event, { data, index })"
              showClear
            >
              <template #option="slotProps">
                {{ slotProps.option.username }} |
                {{ slotProps.option.firstName }} {{ slotProps.option.lastName }}
              </template>
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  {{ dropDownValue(slotProps.value) }}
                </div>
                <div v-else>
                  {{ $t("applications.selectReviewer") }}
                </div>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column
          field="Actions"
          :header="$t('global.actions')"
          :exportable="false"
        >
          <template #body="{ data, index }">
            <div
              class="flex justify-content-evenly align-items-center flex-column"
            >
              <router-link
                v-if="
                  checkRole(pagesPermissions.clients) &&
                  $route.name != 'clientInformation'
                "
                :to="`/clientInformation?userId=${data.userId}`"
                class="flex justify-content-center align-items-center"
              >
                <!-- <img class="actionButtonImage" src="/layout/images/user.png" /> -->
                <span class="mdi mdi-24px mdi-account-box"></span>
              </router-link>
              <!-- <Button
                id="folder"
                class="p-button-text p-0"
                @click="showDownloadFile(data)"
              >
                <span class="mdi mdi-24px mdi-folder-outline"></span>
              </Button> -->
              <Button
                class="p-button-text"
                icon="mdi mdi-24px mdi-eye"
                @click="openView(data)"
                :disabled="
                  $route.name == 'applications'
                    ? data.files == null
                    : Object.keys(data.contract).length == 0
                "
              />
              <Button
                @click="download(data)"
                class="p-button-text"
                icon="mdi mdi-24px mdi-download"
                :disabled="
                  $route.name == 'applications'
                    ? data.files == null
                    : Object.keys(data.contract).length == 0
                "
              />
              <Button
                v-if="
                  checkRole([
                    'application-reviewer',
                    'head-application-reviewer',
                  ]) && changeStatusButtons
                "
                :label="$t('global.reject')"
                class="p-button-danger p-button-sm m-1"
                @click="changeStatus(data, index, 'rejected')"
                iconPos="right"
                :disabled="
                  data.status == 'accepted' || data.status == 'rejected'
                "
                :loading="
                  buttonLoading &&
                  loadingIndex == index &&
                  rejectedButton == false
                "
              />
              <Button
                v-if="
                  checkRole([
                    'application-reviewer',
                    'head-application-reviewer',
                  ]) && changeStatusButtons
                "
                :label="$t('global.approve')"
                class="p-button-success p-button-sm m-1"
                @click="changeStatus(data, index, 'accepted')"
                iconPos="right"
                :disabled="
                  data.status == 'accepted' || data.status == 'rejected'
                "
                :loading="
                  buttonLoading && loadingIndex == index && rejectedButton
                "
              />

              <Button
                v-if="checkRole(['client'])"
                :label="$t('global.edit')"
                class="p-button-success"
                @click="editApplication(data)"
                :disabled="data.status != 'draft'"
              />

              <Button
                v-if="checkRole(['sales']) && contractActions"
                :disabled="data.contract.status == 'accepted'"
                :label="
                  data.contract.id
                    ? $t('contracts.editContract')
                    : $t('contracts.createNewContract')
                "
                class="p-button-sm"
                :class="
                  data.contract.id ? 'p-button-warning' : 'p-button-success'
                "
                @click="contractAction(data, !data.contract.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <template #content v-else-if="!loading">
      <NoTableData :type="'application'"></NoTableData>
    </template>
  </Card>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters, mapState } from "vuex";
import CreateEditContract from "../Contracts/CreateEditContract.vue";
import CommentsFields from "../CommentsField.vue";
import NoTableData from "../NoTableData.vue";
import ViewApplication from "./ViewApplication.vue";
import ViewContract from "../Contracts/ViewContract.vue";

export default {
  name: "Applications table",
  components: {
    CreateEditContract,
    CommentsFields,
    NoTableData,
    ViewApplication,
    ViewContract,
  },
  props: ["changeStatusButtons", "contractActions", "dataUserId"],
  data() {
    return {
      viewDownloadFile: false,
      downloadFileInfo: null,
      loading: true,
      buttonLoading: false,
      loadingIndex: null,
      rejectedButton: false,
      displayContractDialog: false,
      contract: false,
      createEditAction: null,
      animationIndex: null,
      reviewers: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      loadingReviewers: false,
      loadingReviewersIndex: null,
      screenWidth: window.innerWidth,
      openViewApplication: false,
      loadingView: false,
      showViewContract: false,
      contractViewData: {},
      showRejectModal: false,
      rejectApplicationObject: {},
      showRejectError: false,
    };
  },
  computed: {
    ...mapGetters([
      "applications",
      "checkPermission",
      "users",
      "checkRole",
      "translatedStatus",
      "isMobile",
      "certCategoryById"
    ]),
    ...mapState(["pagesPermissions"]),
    dropDownValue() {
      return (id) => {
        let user = this.users.find((el) => el.id == id);

        if (user) {
          return `${user?.username} | ${user?.firstName} ${user?.lastName}`;
        }

        return this.$t("applications.selectReviewer");
      };
    },
  },
  async mounted() {
    this.getApplications();
    console.log(this.$route);
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
    //this.$route.query.appId != "" ? this.$route.query.appId : null
  },
  methods: {
    closeRejectModel() {
      this.showRejectModal = false;
      this.rejectApplicationObject = {};
      this.showRejectError = false;
      this.buttonLoading = false;
    },
    async rejectApplication() {
      if (
        this.rejectApplicationObject.comments != null &&
        this.rejectApplicationObject.comments.trim().length > 0
      ) {
        this.buttonLoading = true;
        await this.$store
          .dispatch("addApplicationComment", this.rejectApplicationObject)
          .then(async (updateApplication) => {
            await this.$store
              .dispatch("updateStatusApplication", {
                id: updateApplication.id,
                version: updateApplication.version,
                status: "rejected",
              })
              .then(() => {
                this.$action(
                  "application-reject",
                  {
                    applicationId: updateApplication.id,
                    rejectionComment: updateApplication.comments,
                  },
                  "application"
                );
              });
          });
        this.closeRejectModel();
        this.buttonLoading = false;
        this.$notification(
          this.$t("applications.changeStatusApplication"),
          this.$t("applications.statusUpdated")
        );
        this.getApplications();
      } else {
        this.showRejectError = true;
      }
    },
    download(data) {
      if (this.$route.name == "applications") {
        this.$store
          .dispatch("downloadFile", {
            data: {
              id: data.files[0],
              name: `${data.companyName}_application`,
            },
          })
          .then(() => {
            this.$action("application-download", { applicationId: data.id });
          });
      } else {
        this.$store
          .dispatch("downloadFile", {
            data: { id: data.contract.fileId, name: data.contract.companyName },
          })
          .then(() => {
            this.$action("contract-download", { contractId: data.contract.id });
          });
      }
    },
    async openView(data) {
      if (this.$route.name == "applications") {
        this.loadingView = true;
        this.openViewApplication = true;
        await this.$store
          .dispatch("downloadFile", {
            data: { id: data.files[0] },
            download: false,
          })
          .then(() => {
            this.$action("application-view", { applicationId: data.id });
          });
        this.loadingView = false;
      } else {
        this.showViewContract = true;
        this.contractViewData = data.contract;
      }
    },
    closeContract() {
      this.showViewContract = false;
      this.contractViewData = {};
    },
    closeViewApplication() {
      this.openViewApplication = false;
    },
    async selectReviewer(event, { data, index }) {
      this.loadingReviewersIndex = index;
      this.loadingReviewers = true;
      let reviewer = this.users.find((el) => el.id == event.value);
      if (reviewer) {
        await this.$store
          .dispatch("assignToApplication", {
            id: data.id,
            version: data.version,
            applicationReviewerId: event.value,
          })
          .then(() => {
            this.$action(
              "application-assign-reviewer",
              {
                applicationId: data.id,
                targetUsername: reviewer.username,
              },
              "application"
            );

            this.$action(
              "application-get",
              {
                applicationId: data.id,
              },
              "",
              reviewer.username,
              reviewer.id,
              "application-reviewer"
            );
          });
      } else {
        await this.$store.dispatch("removeReviewer", {
          id: data.id,
          version: data.version,
        });
      }
      this.loadingReviewers = false;
    },
    exportCSV() {
      this.$refs.tableApplications.exportCSV();
      this.$action("export-applications");
    },
    editApplication(data) {
      this.$store.dispatch("applicationById", data.id);
      this.$router.push({
        name: "companyInformation",
        query: { appId: data.id },
      });
    },
    async changeStatus(application, index, status) {
      try {
        let data = { id: application.id, version: application.version, status };
        if (status == "rejected") {
          this.showRejectModal = true;
          this.rejectApplicationObject = { ...application };
          // this.$confirm.require({
          //   message: this.$t("applications.pleaseAddComment"),
          //   header: `${this.$t("global.warning")}!`,
          //   rejectLabel: this.$t("global.close"),
          //   acceptLabel: this.$t("global.understood"),
          // });
        } else if (status == "accepted") {
          this.showButtonLoading(index, true);
          await this.$store
            .dispatch("updateStatusApplication", data)
            .then(() => {
              this.$action(
                "application-approve",
                { applicationId: data.id },
                "application"
              );
            });

          this.buttonLoading = false;
          this.loadingIndex = null;
          this.$notification(
            this.$t("applications.changeStatusApplication"),
            this.$t("applications.statusUpdated")
          );
          this.getApplications();
        }
      } catch (err) {
        // this.$notification(
        //   this.$t("applications.changeStatusApplication"),
        //   this.$t("global.errorMessage"),
        //   "error"
        // );
      }
    },
    showButtonLoading(index, rejectedButton) {
      this.buttonLoading = true;
      this.loadingIndex = index;
      this.rejectedButton = rejectedButton;
    },
    async getApplications() {
      this.loading = true;
      if (this.contractActions) {
        await this.$store.dispatch("contracts");
      }
      if (this.checkRole(["head-application-reviewer", "admin"])) {
        await this.$store.dispatch("userByRole", "application-reviewer");
      }
      console.log(this.$route);
      await this.$store.dispatch("applications", {
        userId: "userId" in this.$route.query ? this.$route.query.userId : "",
      });
      await this.$store.dispatch("schemes");
      this.loading = false;
      if ("appId" in this.$route.query && this.$route.query.appId != "") {
        this.filters.id.value = parseInt(this.$route.query.appId);
      }
    },
    contractAction(data, createAction) {
      if (data.contract.status == "accepted") return;
      this.displayContractDialog = true;
      this.contract = JSON.parse(JSON.stringify(data));
      this.createEditAction = createAction;
    },
    closeDialog() {
      this.displayContractDialog = false;
      this.contract = {};
    },
    showDownloadFile(data) {
      this.viewDownloadFile = true;
      this.downloadFileInfo = data;
    },
    closeDownloadFile() {
      this.viewDownloadFile = false;
      this.downloadFileInfo = {};
    },
    filterClear(field) {
      this.filters[field].value = null;
    },
    filterApply(field, data) {
      this.filters[field].value = data.value;
    },
  },
};
</script>
