<template>
  <Dialog
    :header="
      contractData
        ? $t('contracts.editContract')
        : $t('contracts.createNewContract')
    "
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '50vw' }"
  >
    <div class="grid flex-column">
      <div class="col">
        <div class="grid vertical-container align-items-center grid-nogutter">
          <div class="col-3 md:col-2">
            <label for="companyName">{{ $t("contracts.companyName") }}:</label>
          </div>
          <div class="col-offset-1 col-8 md:col-6">
            <div class="field">
              <InputText
                id="companyName"
                v-model="data.contract.companyName"
                aria-describedby="fax-error"
                v-if="contractData"
              />
              <InputText
                id="companyName"
                v-model="data.companyName"
                aria-describedby="fax-error"
                :readonly="true"
                v-else
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="grid vertical-container align-items-center grid-nogutter">
          <div class="col-3 md:col-2">
            <label for="companyRepresentativeName"
              >{{ $t("contracts.companyRep") }}:</label
            >
          </div>
          <div class="col-offset-1 col-8 md:col-6">
            <div class="field">
              <InputText
                id="companyRepresentativeName"
                v-model="data.contract.companyRepresentativeName"
                v-if="contractData"
              />
              <InputText
                id="companyRepresentativeName"
                v-model="data.companyRepresentative"
                :readonly="true"
                v-else
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="grid vertical-container align-items-center grid-nogutter">
          <div class="col-3 md:col-2">
            <label for="companyRepresentativeName"
              >{{ $t("contracts.salesPersonName") }}:</label
            >
          </div>
          <div class="col-offset-1 col-8 md:col-6">
            <div class="field">
              <InputText
                id="salesPersonName"
                v-model="data.contract.salesPersonName"
                v-if="contractData"
              />
              <InputText
                id="salesPersonName"
                v-model="userInfo.name"
                :readonly="true"
                v-else
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="grid vertical-container align-items-center grid-nogutter">
          <div class="col-3 md:col-2">
            <label for="comments">{{ $t("contracts.comments") }}:</label>
          </div>
          <div class="col-offset-1 col-8 md:col-6">
            <div class="field">
              <InputText id="comments" v-model="data.contract.comments" />
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="grid vertical-container align-items-center grid-nogutter">
          <div class="col-3 md:col-2">
            <label for="comments">{{ $t("contracts.price") }} *:</label>
          </div>
          <div class="col-offset-1 col-8 md:col-6">
            <div class="field">
              <div class="p-inputgroup">
                <InputNumber
                  id="price"
                  :class="{
                    'p-invalid': v$.data.contract.price.$invalid && submitted,
                  }"
                  v-model="price"
                  :min="0"
                />
                <span class="p-inputgroup-addon">&euro;</span>
              </div>
              <small
                v-if="
                  (v$.data.contract.price.$invalid && submitted) ||
                  v$.data.contract.price.$pending.$response
                "
                class="p-error"
                >{{
                  v$.data.contract.price.required.$message.replace(
                    "[value]",
                    $t("contracts.price")
                  )
                }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="grid vertical-container align-items-center grid-nogutter">
          <div class="col-3 md:col-2">
            <label for="extraCost">{{ $t("contracts.extraCost") }}:</label>
          </div>
          <div class="col-offset-1 col-8 md:col-6">
            <div class="field">
              <div class="p-inputgroup">
                <InputNumber
                  id="extraCost"
                  v-model="extraCost"
                  :min="0"
                />
                <span class="p-inputgroup-addon">&euro;</span>
              </div>
              <!-- <small
                v-if="
                  (v$.data.contract.extraCost.$invalid && submitted) ||
                  v$.data.contract.extraCost.$pending.$response
                "
                class="p-error"
                >{{
                  v$.data.contract.extraCost.required.$message.replace(
                    "[value]",
                    $t("contracts.extraCost")
                  )
                }}</small
              > -->
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>
    <template #footer>
      <Button
        :label="contractData ? $t('global.edit') : $t('global.create')"
        class="p-button-success"
        @click="create"
        autofocus
        iconPos="right"
        :loading="loadingButton && createButton"
        v-if="!contractData && checkRole(['sales'])"
      />
      <Button
        :label="$t('global.send')"
        class="p-button-success"
        @click="send"
        autofocus
        iconPos="right"
        :loading="loadingButton && createButton == false"
        v-else-if="contractData && checkRole(['sales'])"
      />
      <Button
        :label="$t('global.cancel')"
        @click="close"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
export default {
  name: "Create and edit contract",
  props: ["display", "dataValue", "createEditAction"],
  emits: ["closeDialog", "getApplications"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      contractData: false,
      loadingButton: false,
      createButton: false,
      submitted: false,
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
    },
    async viewDialog(val) {
      if (val == false) {
        this.contractData = false;
        this.close();
      } else {
        if (Object.keys(this.data.contract).length > 0) {
          this.contractData = true;
        } else {
          this.data.contract.comments = null;
          this.data.contract.price = null;
          this.data.contract.extraCost = 0;
        }
      }
    },
  },
  validations() {
    return {
      data: {
        contract: {
          price: {
            required,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo", "checkPermission", "checkRole"]),
    data() {
      return { ...this.dataValue };
    },
    price: {
      get() {
        return Number(this.data.contract.price);
      },
      set(val) {
        this.data.contract.price = Number(val);
      },
    },
    extraCost: {
      get() {
        return Number(this.data.contract.extraCost);
      },
      set(val) {
        this.data.contract.extraCost = Number(val);
      },
    },
  },
  methods: {
    close(success = false) {
      this.submitted = false;
      this.$emit("closeDialog", success);
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        this.loadingButton = true;
        this.createButton = true;
        let temp = {
          applicationId: this.data.id,
          companyRepresentativeName: this.data.companyRepresentative,
          salesPersonName: this.userInfo.name,
          status: "prepared",
          comments: this.data.contract.comments,
          price: this.data.contract.price,
          extraCost: this.data.contract.extraCost,
        };
        this.createSendContract(temp);
      }
    },
    send() {
      this.loadingButton = true;
      this.createButton = false;
      let temp = {
        id: this.data.contract.id,
        applicationId: this.data.id,
        companyRepresentativeName: this.data.contract.companyRepresentativeName,
        salesPersonName: this.data.contract.salesPersonName,
        status: "send",
        comments: this.data.contract.comments,
        version: this.data.contract.version,
        price: this.data.contract.price,
        extraCost: this.data.contract.extraCost,
      };
      this.createSendContract(temp);
      console.log(this.data.contract);
    },
    async createSendContract(contract) {
      try {
        switch (this.createButton) {
          case true:
            await this.$store
              .dispatch("createContract", contract)
              .then((value) => {
                this.$action(
                  "contract-new",
                  {
                    contractId: value.data.id,
                    applicationId: contract.applicationId,
                  },
                  "contract"
                );
              });
            this.$notification(
              this.$t("contracts.createNewContract"),
              this.$t("contracts.contractCreated")
            );
            this.$notification(
              this.$t("contracts.createNewContract"),
              this.$t("contracts.reloadTableContracts"),
              "info"
            );
            this.$emit("getApplications");
            this.$emit("closeDialog");
            break;

          case false:
            await this.$store.dispatch("updateContract", contract).then(() => {
              this.$action(
                "contract-edit",
                {
                  contractId: contract.id,
                  applicationId: contract.applicationId,
                },
                "contract"
              );
            });
            this.$notification(
              this.$t("contracts.sendContract"),
              this.$t("contracts.contractWasSend")
            );
            this.$notification(
              this.$t("contracts.sendContract"),
              this.$t("contracts.reloadTableContracts"),
              "info"
            );
            this.$emit("getApplications");
            this.$emit("closeDialog");
            break;
        }
      } catch (err) {
        // this.$notification(
        //   this.$t("contract.sendContract"),
        //   this.$t("global.errorMessage"),
        //   "error"
        // );
      }
      this.loadingButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-inputtext {
  width: 100%;
}

.field {
  margin-bottom: 0;
}
</style>
